import React from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
import { Typography } from "@mui/material";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);

type Props = {
  type: AlertColor;
  title: string;
  subtitle?: string;
  status: boolean;
  switchStatus: () => void;
};

const AlertSnack = (props: Props) => {
  return (
    <React.Fragment>
      <Snackbar
        open={props.status}
        autoHideDuration={3000}
        onClose={() => props.switchStatus()}
      >
        <Alert severity={props.type} sx={{ width: "100%" }}>
          <Typography component="h4" variant="subtitle2" fontWeight={400}>
            {props.title}
          </Typography>
          {props.subtitle ? (
            <Typography component="h4" variant="subtitle2" fontWeight={400}>
              {props.subtitle}
            </Typography>
          ) : (
            <></>
          )}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default AlertSnack;
