import axios from "axios";
import { api_url } from "./config";

export const Autenticate = async (data: {
  username: string;
  password: string;
}): Promise<string> =>
  axios
    .post("/", data, {
      baseURL: api_url(),
    })
    .then((res) => res.data.token);

export const Verify = async (
  token: string
): Promise<{ verify: boolean; username: string | null }> =>
  axios
    .get("/verify", {
      headers: {TOKEN: token},
      baseURL: api_url(),
    })
    .then((res) => res.data.data);
