import { Toolbar, AppBar } from "@mui/material";

type Props = {};

export const TopBar: React.FunctionComponent<Props> = () => {
  return (
    <AppBar sx={{ position: "absolute" }}>
      <Toolbar sx={{ pr: "24px" }}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/logo_engemap.png`}
          alt=""
          width={160}
          loading="lazy"
        />
      </Toolbar>
    </AppBar>
  );
};
