import { Typography, Paper } from "@mui/material";
import React from "react";

type Props = {
  title: string;
  subtitle?: string;
};

type CardsProps = React.PropsWithChildren<Props>;

export const Cards: React.FunctionComponent<CardsProps> = ({ title }) => {
  return (
    <React.Fragment>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          minHeight: 89,
          m: 1,
          justifyContent: "center",
          textAlign: "center",
          backgroundColor: "secondary.main",
        }}
      >
        <Typography component="h2" variant="h6" color="white">
          {title}
        </Typography>
      </Paper>
    </React.Fragment>
  );
};
