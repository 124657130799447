import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Modal,
  Toolbar,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TopBar } from "../components/TopBar";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Verify } from "../api/Auth";
import { Cards } from "../components/Cards";
const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(0,62,81)",
    },
    secondary: {
      main: "rgb(0,179,220)",
    },
  },
});

type ExpiredSessionModalProps = {
  open: boolean;
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ExpiredSessionModal = (props: ExpiredSessionModalProps) => {
  let navigate = useNavigate();
  return (
    <Modal open={props.open}>
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          SESSÃO EXPIRADA OU USUARIO NÃO AUTENTICADO
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
          É necessario que seja realizada a autenticação...
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          sx={{ mt: 2, mb: 2, float: "right" }}
          onClick={() => {
            return navigate("/");
          }}
        >
          <Typography color="white">OK</Typography>
        </Button>
      </Box>
    </Modal>
  );
};

type Props = {};

const Dashboard: React.FunctionComponent<Props> = () => {
  const [loading, setLoading] = useState(true);
  const [autenticated, setAutenticated] = useState(false);
  const handleAuth = async () => {
    try {
      const token = localStorage.getItem("TOKEN");
      if (token) {
        const verify = await Verify(token);
        if (verify.verify) {
          setAutenticated(true);
        } else {
          setAutenticated(false);
          localStorage.removeItem("TOKEN");
        }
      } else {
        setAutenticated(false);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    handleAuth();
  });
  return (
    <>
      {!loading ? (
        <>
          <ThemeProvider theme={theme}>
            {autenticated ? (
              <>
                <Box sx={{ display: "flex" }}>
                  <CssBaseline />
                  <TopBar />
                  <Box
                    component="main"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[100]
                          : theme.palette.grey[900],
                      flexGrow: 1,
                      height: "100vh",
                      overflow: "auto",
                    }}
                  >
                    <Toolbar />
                    <Container maxWidth="xl" sx={{ mt: 3, mb: 6 }}>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        columns={{ xs: 4, sm: 1, md: 4 }}
                        sx={{ marginTop: 1, marginBottom: 1 }}
                      >
                        <Grid item xs={4} sm={1}>
                          <Link
                            to={"#"}
                            style={{ textDecoration: "none" }}
                            onClick={() => {
                              window.open(
                                process.env.REACT_APP_G_SAAPI_CLIENT_URL,
                                "_blank",
                                "noreferrer",
                              );
                            }}
                          >
                            <Cards title="GERENCIADOR SAAPI" />
                          </Link>
                        </Grid>
                        <Grid item xs={4} sm={1}>
                          <Link
                            to={"#"}
                            style={{ textDecoration: "none" }}
                            onClick={() => {
                              window.open(
                                process.env.REACT_APP_C_ATIVOS_CLIENT_URL,
                                "_blank",
                                "noreferrer",
                              );
                            }}
                          >
                            <Cards title="GERENCIADOR PATRIMÔNIO" />
                          </Link>
                        </Grid>
                        <Grid item xs={4} sm={1}></Grid>
                      </Grid>
                    </Container>
                  </Box>
                </Box>
              </>
            ) : (
              <ExpiredSessionModal open={true} />
            )}
          </ThemeProvider>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Dashboard;
