import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import { Autenticate, Verify } from "../api/Auth";
import { useNavigate } from "react-router-dom";
import AlertSnack from "../components/AlertSnack";

type Props = {};

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(0,62,81)",
    },
    secondary: {
      main: "rgb(0,179,220)",
    },
  },
});

const Login: React.FunctionComponent<Props> = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const switchErrorStatus = () => setErrorStatus(!errorStatus);
  localStorage.removeItem("TOKEN");
  const [data, setData] = useState({ username: "", password: "" });
  const verifyAuth = async (data: { username: string; password: string }) => {
    handleOpen();
    try {
      if (data) {
        const token = await Autenticate(data);
        const verify = await Verify(token);
        if (verify.verify) {
          localStorage.setItem("TOKEN", token);
          return navigate("/dashboard");
        }
      }
      setErrorStatus(true);
    } catch (e) {
      console.log(e);
      setErrorStatus(true);
    }
    handleClose();
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url('/assets/login_img.png')",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo_engemap_d.png`}
              alt=""
              width={340}
              style={{ marginLeft: -12 }}
            />
            <Typography component="h1" variant="h5" sx={{ mt: 8 }}>
              Autenticação
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                required
                fullWidth
                autoFocus
                id="user"
                label="Usuario"
                color="secondary"
                onChange={(e) => setData({ ...data, username: e.target.value })}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Senha"
                type="password"
                id="password"
                color="secondary"
                onChange={(e) => setData({ ...data, password: e.target.value })}
              />
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => {
                  verifyAuth(data);
                }}
              >
                <Typography component="h3" variant="subtitle2">
                  Autenticar
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Backdrop
        open={open}
        onClick={() => null}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {errorStatus ? (
        <AlertSnack
          switchStatus={switchErrorStatus}
          title="IMPOSSIVEL AUTENTICAR"
          status={errorStatus}
          type="error"
        />
      ) : (
        <></>
      )}
    </ThemeProvider>
  );
};

export default Login;
